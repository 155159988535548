<template>
     <div class="form-group">
        <div class="btn-group dropdown margin-r-5">
            <button type="button" class="btn btn-primary"> <i class="fa fa-send"></i>
            Send</button>										 
            <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" v-on:click=" sendBtn = !sendBtn " >
                    <span class="caret"></span>
                </button>
                  <ul class="dropdown-menu dropdown-menu-btn-option" id="btnSend" v-bind:class = "sendBtn?'show':''" v-if="sendBtn" v-click-outside="closeEvent">
                    <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-send-leave-open"></i> Leave Open</li>
                    <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-expect-customer-reply"></i> Expect Customer Reply</li>
                    <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-add-reference-number"></i> Add Reference Number</li>
                    <li>
                      <a href="javascript:void(0)" class="text-primary" v-on:click="shownotificationdropdown = !shownotificationdropdown" ><i class="fa icon-c icon-send-secure"></i> Send Secure (Notification)..</a>
                      <div v-if="shownotificationdropdown">
                        <select class="form-control">
                          <option>Select a notification</option>
                        </select>
                      </div>
                      <div class="form-alert-message">
                        <div class="alert alert-info alert-dismissible mtop-5">
                            <button type="button" class="close">×</button>
                              <div class="message-container">
                                  <div class="icon">
                                    <i class="icon fa fa-info-circle"></i>
                                  </div>
                                  <div class="message"><strong>This Segment does not have a Secure Notification configured.</strong><div>
                                  You must create a Secure Notification before using the Send Secure action.
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </li>
                  </ul>
            </div>
        </div>
        <button class="btn btn-primary" type="button">
            <i class="fa  fa-check"></i>
            Resolve
        </button>
      
        <div class="btn-group margin-r-5" uib-dropdown dropdown-append-to-body auto-close="outsideClick">
            <button type="button" class="btn btn-primary"  v-on:click=" forwardBtn = !forwardBtn " >
                <i class="fa fa-mail-forward"></i>
                Forward
            </button>
            <ul class="dropdown-menu dropdown-menu-btn-option dropdown-forward" v-bind:class = "forwardBtn?'show':''" v-if="forwardBtn" v-click-outside="closeEvent">
                <li v-bind:class = "selectAgent ?'option-selected':''">
                  <a href="javascript:void(0)" class="text-primary" v-on:click="selectAgent = !selectAgent;forwardSegment=false;forwardExternalSegment=false;forwardaCopy=false;"><i class="fa icon-c icon-forward-to-agent"></i> Forward To Agent</a>
                  <div class="btn-list-options form-group" v-if="selectAgent">
                    <div class="n">
                      <Multiselect
                        v-model="value"
                        placeholder="Select or search for an agent in the list"
                        label="name"
                        :searchable="true"
                        :options="[
                          { value: 'alek packard', name: 'Alek Packard',email:'alek.packard@example.com',status:'',disabled: false  },
                          { value: 'jacob smith', name: 'Jacob Smith',email:'jacob.smith@example.com',status:'notavailable',disabled: true  },
                          { value: 'jonnie hewlett', name: 'Jonnie Hewlett',email:'jonnie.hewlett@example.com',status:'logout',disabled: false  },
                        ]"
                      >
                        <template v-slot:singlelabel="{ value }">
                          <div class="multiselect-single-label">
                              {{ value.name }}
                          </div>
                        </template>

                        <template v-slot:option="{ option }">
                          <div>
                            {{ option.name }} <span v-if=" option.status == 'notavailable'"><i class="fa  fa-ban text-red"   data-bs-toggle="tooltip" data-bs-placement="bottom"  title="This agent is not available because they  are logged out and have the Empty Inbox setting"></i></span>
                            <span v-if=" option.status == 'logout'"><i class="fa fa-fw fa-sign-out text-red" data-bs-toggle="tooltip" data-bs-placement="bottom" title="logged out agent"></i></span>
                            <br/> {{ option.email}}
                          </div>
                        </template>
                      </Multiselect>
                    </div>
                    <button class="btn btn-primary btn-xs">Assign To Agent</button>
                  </div>
                </li>
                <li><a href="javascript:void(0)" class="text-primary"><i class="fa icon-c icon-unassign-question"></i> Unassign Question</a></li>
                <li v-bind:class = "forwardSegment ?'option-selected':''">
                  <a href="javascript:void(0)" class="text-primary" v-on:click="forwardSegment = !forwardSegment;selectAgent=false;forwardExternalSegment=false;forwardaCopy=false;"> <i class="fa icon-c icon-forward-to-segment"></i> Forward To Segment</a>
                  <div class="btn-list-options form-group" v-if="forwardSegment">
                    <div class="n">
                        <Multiselect
                          v-model="value"
                          placeholder="Select or search for a mailbox to receive interaction"
                          label="name"
                          :groups="true"
                          :searchable="true"
                          :options="[
                            {
                              label: 'Customer Success',
                              options:  [
                                  { value: 'sales', name: 'Sales',email:'sales@1to1service.com'},
                                  { value: 'technical', name: 'Technical',email:'technical@1to1service.com'},
                              ],
                            },
                            {
                              label: 'Human Resource',
                              options:  [
                                  { value: 'benefits', name: 'Banefits',email:'benefits@1to1service.com'},
                                  { value: 'hr', name: 'Human Resource',email:'hr@1to1service.com'},
                              ],
                            }
                          ]"
                        >
                        <template v-slot:option="{ option }">
                            {{ option.name }} 
                            <br/> {{ option.email}}
                        </template>
                      </Multiselect>

                    </div>
                    <button class="btn btn-primary btn-xs">Forward Now</button>
                  </div>
                </li>
                <li v-bind:class = "forwardExternalSegment ?'option-selected':''">
                  <a href="javascript:void(0)" class="text-primary" v-on:click="forwardExternalSegment = !forwardExternalSegment;selectAgent=false;forwardSegment=false;forwardaCopy=false;"><i class="fa icon-c icon-forward-to-external-agent"></i> Forward To External Agent</a>
                  <div class="btn-list-options form-group" v-if="forwardExternalSegment">
                    <div class="form-group" >
                      <Multiselect
                        v-model="value"
                        mode="multiple"
                        placeholder="Select or search for notification template"
                        :searchable="true"
                        :closeOnSelect="false"
                        :options="{
                          template: 'Template 1',
                          template: 'Template 2'
                        }"
                      />
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Enter the email address for the external agent">
                      <button class="btn btn-primary btn-xs">Forward Now</button> 
                    </div>
                  </div>
                </li>
                <li v-bind:class = "forwardaCopy ?'option-selected':''">
                <a href="javascript:void(0)" class="text-primary" v-on:click="forwardaCopy = !forwardaCopy;selectAgent=false;forwardSegment=false;forwardExternalSegment=false;"><i class="fa fa-mail-forward"></i> Forward a Copy
                  </a>  
                  <div class="btn-list-options form-group" v-if="forwardaCopy">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Enter the email address for the recipient">
                      <button class="btn btn-primary btn-xs">Forward Now</button> 
                    </div>
                  </div>
                </li>
              </ul>
        </div>
   
        <div class="btn-group dropdown margin-r-5">
            <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Save a draft">
              <i class="fa fa-save"></i>
              Save
            </button>
            <div class="btn-group">
              <button type="button" class="btn btn-primary dropdown-toggle" v-on:click=" saveBtn = !saveBtn " >
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-btn-option dd-width-auto" v-bind:class = "saveBtn?'show':''" v-if="saveBtn" v-click-outside="closeEvent">
                <li><a href="#" class="text-primary"><i class="fa icon-c icon-private-note"></i>Private Note</a></li>
                <li><a href="#" class="text-primary"><i class="fa icon-c icon-public-note"></i>Public Note</a></li>
              </ul>
            </div>
        </div>
        <div class="btn-group margin-r-5" uib-dropdown dropdown-append-to-body auto-close="outsideClick">
            <button class="btn btn-primary " title="Spam"  v-on:click=" changeTopicBtn = !changeTopicBtn "> 
                <i class="fa fa-arrows-alt"></i>
                Change Topic
            </button>
              <ul class="dropdown-menu dropdown-menu-btn-option dropdown-forward change-topic" v-bind:class = "changeTopicBtn?'show':''" v-if="changeTopicBtn" v-click-outside="closeEvent">
                <li>
                    <Multiselect
                          v-model="value"
                          placeholder="Select topic"
                          label="name"
                          :groups="true"
                          :searchable="true"
                          :options="[
                            {
                              label: 'Customer Success',
                              options:  [
                                  { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                  { value: '_Feedback', name: '_Feedback',level:'2'},
                                  { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                  { value: '_Spam', name: '_Spam',level:'2'},
                                  { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                  { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                  { value: 'Return', name: 'Return',level:'2'},
                                  { value: 'Return', name: 'Return',level:'2'},
                                  { value: 'Cancel', name: 'Cancel',level:'2'},
                              ],
                            }
                          ]"
                        >
                        <template v-slot:option="{ option }">
                          <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                        </template>
                      </Multiselect>
                </li>
              </ul>  
        </div>
        <button class="btn btn-primary" title="Spam">
            <i class="fa fa-ban"></i>
            Spam
        </button>
        <a class="btn btn-link" v-on:click="cancel('getthiscontent')">Cancel</a>

        <div class="btn-group pull-right dropdown" auto-close="outsideClick">
            <button type="button" class="btn btn-default" v-on:click = "toggleMore = !toggleMore" v-tooltip="{text: 'more',theme: {placement: 'bottom', }}">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-right dropdown-bg dropdown-more" v-bind:class = "toggleMore?'':'show'">
                <li role="menuitem" ng-class="{ 'bg-primary': isStatusAuditSectionExpanded }">
                   
                        <label class="switch">
                            <Toggle v-model="statusaudit" />
                            Show Status Audit
                        </label>
                    
                </li>
                <li role="menuitem">
                    
                        <label class="switch">
                            <Toggle v-model="fullcasehistory" />
                            Show Full Case
                        </label>
                   
                </li>
                <li role="menuitem">
                        <label class="switch">
                            <Toggle v-model="palintext" />
                            Display Plain Text
                        </label>
                </li>
                <li role="menuitem"><a href="#"><i class="fa fa-print"></i> Print</a></li>
                <li role="menuitem" class="text-red">
                    <a href="unsafe:javascript:void(0)" class="text-red" v-on:click="showconfirmdelete  = true;" v-show="!showconfirmdelete" style=""><i class="fa  fa-trash"></i> Delete This Interaction</a>
                    <div v-show="showconfirmdelete" class="ng-hide" style="">
                        <p class="text-red">Do you really want to delete this interaction?</p>
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="cancel('getthis');toggleRow('row1'); showDeleteSuccess = true;">Confirm Deletion</button>
                            <button type="button" class="btn btn-link" v-on:click="showconfirmdelete = false">Cancel</button>
                        </div>
                    </div>
                </li>
                <li role="menuitem"><a href="#">
                  <i class="fa  fa-retweet"></i>
                    
                    Reopen this interaction</a>
                  </li>
            </ul>
        </div>
    </div> 
</template>

<script>
    import Multiselect from '@vueform/multiselect'


    export default {
      name: 'ActionModeButtonsComponents',
       components: {
         Multiselect
      },
      data: function () {
        return {
          shownotificationdropdown: false, 
          sendBtn: false,
          forwardBtn:false,
          myvar : '',
          selectAgent : false,
          forwardSegment : false,
          forwardaCopy : false,
          forwardExternalSegment : false,
          saveBtn: false,
          changeTopicBtn: false,
          showconfirmdelete:false,
          toggleMore : true,
          
        }
      },
      events: {
        closeEvent: function () { alert("dd");
         
        }
      },
      methods: {
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }

    }
</script>